<template>
  <v-container fluid class="fill-height ma-0 pa-0">
    <banner-component />
    <search-panel-component />
    <!-- <about-as-component /> -->
    <!-- <features-component /> -->
    <the-best-tijuana-component />
    <div style="width: 50%">
      <medical-tour-component />
    </div>
    <div style="width: 50%">
      <certified-doctors-component />
    </div>
    <specialties-component />
    <cultural-voyage-component />
    <!-- <last-news-component/> -->
    <!-- <comments-component /> -->
    <!-- Footer of application -->
    <footer-component />
  </v-container>
</template>

<script>
import BannerComponent from "@/components/home/BannerComponent.vue";
// import AboutAsComponent from "@/components/home/AboutAsComponent.vue";
// import FeaturesComponent from "@/components/home/FeaturesComponent.vue";
import TheBestTijuanaComponent from "@/components/home/TheBestTijuanaComponent.vue";
import MedicalTourComponent from "@/components/home/MedicalTourComponent.vue";
import CertifiedDoctorsComponent from "@/components/home/CertifiedDoctorsComponent.vue";
import SpecialtiesComponent from "@/components/home/SpecialtiesComponent.vue";
import CulturalVoyageComponent from "@/components/home/CulturalVoyageComponent.vue";
// import LastNewsComponent from '@/components/home/LastNewsComponent.vue';
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
// import CommentsComponent from "@/components/comments/CommentsComponent";
import SearchPanelComponent from "@/components/home/SearchPanelComponent";

export default {
  name: "HomePageComponent",
  components: {
    SearchPanelComponent,
    BannerComponent,
    // AboutAsComponent,
    // FeaturesComponent,
    TheBestTijuanaComponent,
    MedicalTourComponent,
    CertifiedDoctorsComponent,
    SpecialtiesComponent,
    CulturalVoyageComponent,
    // LastNewsComponent,
    // CommentsComponent,
    FooterComponent,
  },
  mounted() {
    this.$store.commit("HideSearchPanel");
  },
};
</script>
