<template>

  <v-card width="100%" class="fill-height container--fluid pa-0 ma-0" tile>
    <!--
    <v-scroll-x-transition appear>
      <v-img
        src="@/assets/images/cover-doctor-web.png"
        class="grey lighten-2"
        transition="slide-x-transition"
        width="100%"
        height="650"
        position="bottom"
      >
    -->


    <v-carousel
      hide-delimiters
      show-arrows
      cycle
      interval="10000"
      style="height: 500px !important; width: 100%"
      v-if="!loading"
    >
      <v-carousel-item
        v-for="(image, index) in images"
        :key="index"
      >
        <v-img :src="image" class="grey lighten-2" width="100%" height="650" position="bottom">

          <div
            class="fill-height container--fluid"
            style="height: 500px; width: 100%"
          >
            <v-container fluid class="fill-height align-end pb-10">
              <v-row align="end" justify="start">
                <v-col cols="12" class="pl-5 pl-md-15">
                  <v-row align="start" justify="start">
                    <v-responsive :max-width="$vuetify.breakpoint.xs ? 350 : 550">
                      <div class="white--text mx-2">
                        <p>
                          <span
                            class="text-h5 text-md-h4 text-uppercase font-weight-bold"
                          >
                            Welcome to the Baja California Health Tourism Platform
                          </span>
                        </p>
                      </div>
                    </v-responsive>
                  </v-row>
                  <v-row>
                    <div style="height: 25px" />
                  </v-row>
                  <v-row align="start" justify="start">
                    <v-responsive
                      :max-width="$vuetify.breakpoint.xsOnly ? 350 : 500"
                    >
                      <div class="white--text mx-2">
                        <p class="text-left font-weight-light text-subtitle-4">
                          The Tourism Secretariat of the State of Baja California
                          provides you with a platform that takes advantage of the
                          innovation and growth of medical tourism in Baja
                          California.
                        </p>
                      </div>
                    </v-responsive>
                  </v-row>
                  <v-row align="start" justify="start">
                    <div class="white--text mx-2">
                      <span class="text-subtitle-1 font-weight-light">
                        Find a Certified Doctor
                      </span>
                    </div>
                  </v-row>
                  <v-row align="start" justify="start">
                    <v-responsive max-width="400" style="margin-bottom: 100px !important;">
                      <div class="white--text mx-2">
                        <v-text-field
                          v-model="searchText"
                          class="rounded-lg"
                          id="findtext-banner"
                          label="Doctor/Hospital/Specialty/Services"
                          name="find"
                          placeholder="Doctor/Hospital/Specialty/Services"
                          outlined
                          solo
                          single-line
                          clearable
                          background-color="white"
                          light
                          prepend-inner-icon="mdi-magnify"
                          @keydown.enter="enterSearch"
                          @click:clear="clearTextSearch"
                          @click:prepend-inner="enterSearch"
                        >
                        </v-text-field>
                      </div>
                    </v-responsive>
                  </v-row>
                  <v-row v-show="false">
                    <div style="height: 10px" />
                  </v-row>
                  <v-row align="start" justify="start" v-show="false">
                    <div class="white--text mx-5">
                      <v-row align="start" justify="space-between">
                        <v-col>
                          <v-img
                            src="@/assets/images/baja-norte-logo.png"
                            width="55"
                            height="64"
                          ></v-img>
                        </v-col>
                        <v-col>
                          <v-img
                            src="@/assets/images/BCHVHC-logo.png"
                            width="64"
                            height="64"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </div>

        </v-img>
      </v-carousel-item>
    </v-carousel>
    <!--
      </v-img>
    </v-scroll-x-transition>
    -->
  </v-card>

</template>

<script>
import { mapMutations } from "vuex";
import carouselServices from '@/providers/CarouselService';

export default {
  name: "BannerComponent",
  data: () => ({
    searchText: null,
    images: [],
    imagesAux: [
      require('@/assets/images/cover-doctor-web.png'),
      require('@/assets/images/cover-doctor-web-banner1.png'),
      require('@/assets/images/cover-doctor-web-banner2.png'),
    ],
    loading: false,
  }),
  mounted(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loading = true;
      carouselServices.getCarousel().then((resp)=>{
        this.images = resp.value.map(e=>{
          return e.url_image;
        });
        if(!this.images || this.images.length === 0) {
          this.images = this.imagesAux;
        }
      }).catch(err=>console.log(err)).finally(()=>{
        this.loading = false;
      })
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    enterSearch(event) {
      console.log(event);
      this.SetSearchText(this.searchText);
      this.$store.commit("ShowSearchPanel");
      this.$store.dispatch("SearchText");
      this.searchText = null;
      // this.toTop();
    },
    clearTextSearch() {
      this.searchText = null;
      this.$store.commit("HideSearchPanel");
      this.$store.commit("ClearSearchText");
      this.$store.commit("ClearResultSearch");
    },
    ...mapMutations(["SetResultSearch", "SetSearchText"]),
  },
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(114, 217, 235, 0.185) 0%,
    rgba(101, 99, 248, 0.911) 100%
  );
}
</style>
