<template>
  <v-container fluid class="fill-height justify-center py-15 text-center">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-row justify="center">
          <span class="blue--text font-weight-regular text-uppercase">
            World famous specialists
          </span>
        </v-row>
        <v-row justify="center" mb-6 pb-6>
          <h1
            class="text-h5 text-md-h4 font-weight-regularf mb-3 headline text-uppercase"
          >
            Everything you need
          </h1>
        </v-row>
        <v-row justify="center">
          <v-responsive :max-width="$vuetify.breakpoint.xsOnly ? 350 : 700">
            <p
              class="pt-4 text-body-1 font-weight-light grey--text text--darken-2"
            >
              Baja California is home to some of the best specialists throughout
              Mexico and the world, rest assured that we will put your available
              the most qualified doctors who have the high quality standards and
              certifications.
            </p>
          </v-responsive>
        </v-row>
        <v-row justify="center" mb-6 pb-6>
          <div class="white--text mx-0 pt-6">
            <v-btn color="primary" plain text to="/doctors" transparent
              ><span>See all</span>
              <v-icon small>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SpecialtiesComponent",
};
</script>

<style scoped>
/* .backcolor {
  background-color: rgba(229, 223, 240, 2);
} */

.v-btn {
  text-transform: none !important;
}
</style>
