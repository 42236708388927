<template>
  <v-container fluid class="pa-0 ma-0">
    <v-card class="d-flex pa-0 ma-0" tile>
      <v-scroll-x-transition appear>
        <v-img
          src="@/assets/images/cover-experiencias.jpg"
          class="blue lighten-2"
          position="center"
          transition="slide-x-transition"
          width="100%"
          height="450"
        >
          <div class="fill-height bottom-gradient" style="width: 100%">
            <v-container fluid class="fill-height align-end pb-10">
              <v-row align="end" justify="start">
                <v-col cols="12" class="pl-5 pl-md-15">
                  <v-row align="start" justify="start">
                    <div class="white--text mx-2">
                      <span class="text-uppercase font-weight-bold"
                        >A cultural trip</span
                      >
                    </div>
                  </v-row>
                  <v-row align="start" justify="start">
                    <div class="white--text mx-2">
                      <span
                        class="text-h5 text-md-h4 text-uppercase font-weight-bold"
                        >Merging two worlds</span
                      >
                    </div>
                  </v-row>
                  <v-row>
                    <div style="height: 25px" />
                  </v-row>
                  <v-row align="start" justify="start">
                    <div class="white--text mx-2" style="max-width: 500px">
                      <p class="text-left font-weight-light text-subtitle-1">
                        Live the experience of our tourist routes: visit
                        beautiful natural attractions, try our gastronomy and
                        let yourself be pampered in the best hotels. Enjoy the
                        unique features that Baja California has it for you.
                      </p>
                    </div>
                  </v-row>
                  <v-row>
                    <div style="height: 10px" />
                  </v-row>
                  <v-row align="start" justify="start" v-show="false">
                    <div class="white--text mx-0">
                      <v-btn transparent text color="white" plain to="/leisures"
                        ><span>Ver más</span>
                        <v-icon small>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-img>
      </v-scroll-x-transition>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CulturalVoyageComponent",
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(235, 114, 114, 0.1) 0%,
    rgba(255, 153, 153, 0.945) 100%
    /*rgba(255, 40, 40, 0.945) 100%,*/
    /* rgb(128, 170, 170) 95%  */
  );
}

.v-btn {
  text-transform: none !important;
}
</style>
