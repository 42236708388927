<template>
  <div>
    <home-page-component />
  </div>
</template>

<script>
import HomePageComponent from "@/components/home/HomePageComponent.vue";

export default {
  name: "HomePage",
  title: "Home | Baja California Health Tourism",
  components: {
    HomePageComponent,
  },
};
</script>
