import { render, staticRenderFns } from "./CulturalVoyageComponent.vue?vue&type=template&id=5f167640&scoped=true"
import script from "./CulturalVoyageComponent.vue?vue&type=script&lang=js"
export * from "./CulturalVoyageComponent.vue?vue&type=script&lang=js"
import style0 from "./CulturalVoyageComponent.vue?vue&type=style&index=0&id=5f167640&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f167640",
  null
  
)

export default component.exports